import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/reset.css";
import "./styles/toast.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "./context/ToastContext";
import { AuthProvider } from "./context/AuthContext";
import ErrorBoundary from "./ErrorBoundary";
import { AxiosInterceptor } from "./api/interceptor";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ErrorBoundary>
      <ToastProvider>
        <AuthProvider>
          <AxiosInterceptor>
            <App />
          </AxiosInterceptor>
        </AuthProvider>
      </ToastProvider>
    </ErrorBoundary>
  </BrowserRouter>
);
