import { useAuth } from "./context/AuthContext";
import { Routes, Route } from "react-router-dom";
import Login from "./screens/Login";
import "./styles/global.scss";
import Header from "./components/Header";
import Dashboard from "./screens/Dashboard";

export default function App() {
  const { loggedIn } = useAuth();
  // Check for devices smaller than tablets
  if (window.innerWidth < 768) {
    return (
      <div className="boundary-div">
        <p>Please use a tablet/laptop/desktop to access this tool.</p>
      </div>
    );
  }

  return (
    <div className="app">
      <Header />
      <Routes>
        {loggedIn ? (
          <Route path="/" element={<Dashboard />} />
        ) : (
          <Route path="/" element={<Login />} />
        )}
      </Routes>
    </div>
  );
}
