import api from "../interceptor";
export const fetchLoginToken = async (googleToken, id) => {
  try {
    const request = {
      url: "/login",
      method: "POST",
      headers: {
        Authorization: `Bearer ${googleToken}`,
        "Content-type": "application/json",
      },
      data: {},
    };
    const response = await api(request);
    return { data: response.data, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
};
