import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useToast } from "../context/ToastContext";

const instance = axios.create({
  baseURL: "/api/v1",
});

const AxiosInterceptor = ({ children }) => {
  const { showToast } = useToast();
  const { logout } = useAuth();

  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    const responseErrorInterceptor = (error) => {
      if ([401, 403].includes(error.response.data?.status)) logout();
      !error.response.data?.details?.hideError &&
        showToast(error.response.data.message, "error");
      return Promise.reject(error);
    };

    const requestConfigInterceptor = (config) => {
      let token = localStorage.getItem("token");
      if (config && !config.headers["Authorization"])
        config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    };

    const RequestInterceptor = instance.interceptors.request.use(
      requestConfigInterceptor,
      undefined
    );

    const ResponseInterceptor = instance.interceptors.response.use(
      undefined,
      responseErrorInterceptor
    );

    setIsSet(true);

    return () => {
      instance.interceptors.request.eject(RequestInterceptor);
      instance.interceptors.response.eject(ResponseInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isSet && children;
};

export default instance;
export { AxiosInterceptor };
