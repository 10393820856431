import React, { createContext, useState, useEffect } from "react";
import { fetchLoginToken } from "../api/services/user";
import { signInWithGoogle } from "../config/firebase";

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  window.addEventListener("storage", (e) => {
    if (
      e.target.sessionStorage.length === 0 &&
      e.target.localStorage.length === 0
    ) {
      setLoggedIn(false);
      setToken("");
    }
  });

  const [token, setToken] = useState(
    sessionStorage.getItem("token") || localStorage.getItem("token") || ""
  );
  const [loggedIn, setLoggedIn] = useState(!!token);

  const setUserDetails = (user, profile_picture) => {
    setToken(user.userToken);
    const storage = localStorage;
    storage.setItem("token", user.userToken);
    storage.setItem("name", user.name);
    storage.setItem("email", user.email);
    storage.setItem("profile_picture", profile_picture);
    setLoggedIn(true);
  };

  const loginByGoogle = async (callback) => {
    try {
      const { user } = await signInWithGoogle();
      callback(true);
      const google_token = await user.getIdToken();
      const { data, error } = await fetchLoginToken(google_token);
      if (error) return { error };
      setUserDetails(data, user.photoURL);
      return { success: true, name: data.name };
    } catch (e) {
      console.log(e);
      return { success: false };
    }
  };

  const logout = () => {
    localStorage.clear();
    setLoggedIn(false);
  };

  const parseJwt = (token) => {
    if (!token) return null;
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const expired = parseJwt(token)?.exp * 1000 || 0;
    if (token && expired > new Date().getTime()) {
      setLoggedIn(true);
    } else if (token) {
      logout();
    }
  }, []);

  const authContextValue = {
    loggedIn,
    logout,
    loginByGoogle,
    token,
    setUserDetails,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
