import React, { createContext, useRef, useContext, useCallback } from "react";
import { Toast } from "primereact/toast";

const ToastContext = createContext(null);

const ToastProvider = ({ children }) => {
  const toast = useRef(null);

  const showToast = useCallback(
    (message, type = "info", detail = "") => {
      toast.current.show({
        severity: type,
        summary: message,
        detail,
        life: 5000,
      });
    },
    [toast]
  );

  return (
    <ToastContext.Provider value={{ showToast }}>
      <Toast ref={toast} />
      {children}
    </ToastContext.Provider>
  );
};

const useToast = () => useContext(ToastContext);

export { ToastProvider, useToast };
