import { OverlayPanel } from "primereact/overlaypanel";
import { useAuth } from "../context/AuthContext";
import "../styles/Header.scss";
import quantiphi_logo from "../assets/images/quantiphi_logo.svg";
import { useRef, useState } from "react";

export default function Header(props) {
  const name = localStorage.getItem("name");
  const email = localStorage.getItem("email");
  const { loggedIn, logout } = useAuth();
  const ref = useRef(null);
  const [menuVisible, setMenuVisible] = useState(false);
  return (
    <div className="header">
      <img src={quantiphi_logo} alt="Quantiphi" />
      {loggedIn && (
        <div
          className="icon_wrapper"
          onClick={(e) => {
            ref.current && ref.current.toggle(e);
          }}
        >
          <img
            className="icon"
            src={localStorage.getItem("profile_picture")}
            alt=""
          ></img>
          <OverlayPanel
            onHide={() => setMenuVisible(false)}
            onShow={() => setMenuVisible(true)}
            ref={ref}
            className={"dropdown_box"}
          >
            <div className={"dropdown_item"}>
              <div className={"user_info"}>
                <span>{name}</span>
                <span>{email}</span>
              </div>
            </div>
            <div className="dropdown_item" onClick={logout}>
              <span className={"log_out"}>Logout</span>
            </div>
          </OverlayPanel>
        </div>
      )}
    </div>
  );
}
