import api from "../interceptor";

export const startDeployment = async (data) => {
  try {
    const request = {
      url: "/start-deployment",
      method: "POST",
      data,
    };
    const response = await api(request);
    return { success: true, data: response.data.details, error: "" };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getAccountDetails = async () => {
  try {
    const request = {
      url: "/account-details",
      method: "GET",
    };
    const response = await api(request);
    return { success: true, data: response.data.details, error: "" };
  } catch (e) {
    return { data: null, error: e };
  }
};
