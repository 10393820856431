import React from "react";
import { logError } from "./api/services/logger";
import "./styles/global.scss";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    //  api call to backend log endpoint
    // logError({
    //   message: `${error.message} at path ${window.location.href}`,
    //   stack: error.stack,
    // });
  }

  render() {
    if (this.state.hasError) {
      // Render any custom fallback UI
      return (
        <div className="error-boundary-container">
          <i className="pi pi-info-circle"></i>
          <h1>
            Something went wrong. We have notified our development team.
            Apologies for the inconvenience.
            <br />
            <a href="/">Click here</a>&nbsp;to go back to homepage.
          </h1>
        </div>
      );
    }

    return this.props.children;
  }
}
