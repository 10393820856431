import "../styles/Login.scss";
import { useAuth } from "../context/AuthContext";
import { useToast } from "../context/ToastContext";

import baioniq_logo from "../assets/images/baioniq_logo.svg";
import google_logo from "../assets/images/google.svg";
import { useState } from "react";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const { loginByGoogle } = useAuth();
  const { showToast } = useToast();

  const handleGoogleLogin = async (e) => {
    e.preventDefault();
    const { name, success } = await loginByGoogle(setLoading);
    setLoading(false);
    if (success) {
      showToast("Login Successful", "success", `Welcome to Baioniq, ${name}`);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-box">
        <img src={baioniq_logo} alt="Baioniq" />
        <span>Generative AI from Quantiphi</span>
        {loading ? (
          <i className="pi pi-spin pi-spinner" />
        ) : (
          <button disabled={loading} onClick={handleGoogleLogin}>
            <span>
              <img src={google_logo} alt="Sign In With Google" />
            </span>
            Sign In with Google
          </button>
        )}
      </div>
    </div>
  );
}
