import { getAuth, GoogleAuthProvider, signInWithPopup } from "@firebase/auth";
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC96rLyNm3qLlLzTDbKrIyUPYKmVbR_r-A",
  authDomain: "quantiphi-public-376012.firebaseapp.com",
  projectId: "quantiphi-public-376012",
  storageBucket: "quantiphi-public-376012.appspot.com",
  messagingSenderId: "795546836796",
  appId: "1:795546836796:web:745755e0cdc58bc1108839",
};

const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = provider;
export const signInWithGoogle = () => signInWithPopup(auth, googleProvider);
