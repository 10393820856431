import "../styles/Dashboard.scss";
import { useToast } from "../context/ToastContext";
import { ProgressBar } from "primereact/progressbar";

import baioniq_logo from "../assets/images/baioniq_logo.svg";
import deployment_img from "../assets/images/deployment_img.svg";
import { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../config/firebase";
import { getAccountDetails, startDeployment } from "../api/services/account";

export default function Dashboard(props) {
  const { showToast } = useToast();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [deploymentDetails, setDeploymentDetails] = useState({
    uid: "",
    status: "",
    stages: {
      ipCreated: false,
      dbCreated: false,
      instanceCreated: false,
      userCreated: false,
    },
  });
  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    orgName: "",
    businessEmail: localStorage.getItem("email"),
    adminEmail: localStorage.getItem("email"),
    contact: "",
    domain: "",
  });

  const handleChange = (e) =>
    setDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const completed = deploymentDetails.stages?.userCreated
    ? 100
    : deploymentDetails.stages?.instanceCreated
    ? 80
    : deploymentDetails.stages?.dbCreated
    ? 40
    : deploymentDetails.stages?.ipCreated
    ? 20
    : 0;

  const stageName = deploymentDetails.stages?.userCreated
    ? "Instance Ready"
    : deploymentDetails.stages?.instanceCreated
    ? "Setting up User Account"
    : deploymentDetails.stages?.dbCreated
    ? "Setting up the instance"
    : deploymentDetails.stages?.ipCreated
    ? "Setting up the database"
    : "Setting up the domain";

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!details.firstName.trim() || !details.lastName.trim())
      return showToast(
        "Invalid Name",
        "error",
        "Please provide a valid first and last name"
      );
    if (details.domain.startsWith("-") || details.domain.endsWith("-"))
      return showToast(
        "Invalid Domain Name",
        "error",
        "Domain name cannot start or end with '-'"
      );
    const regex = /^[A-Za-z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/;
    if (!regex.test(details.businessEmail))
      return showToast(
        "Invalid Business Email",
        "error",
        "Please provide a valid admin email."
      );
    if (!regex.test(details.adminEmail))
      return showToast(
        "Invalid Admin Email",
        "error",
        "Please provide a valid admin email."
      );
    if (!details.orgName.trim())
      return showToast(
        "Invalid Organization Name",
        "error",
        "Please provide a valid organization name"
      );

    if (!details.contact.trim())
      return showToast(
        "Invalid Contact",
        "error",
        "Please provide a valid contact number"
      );
    setSubmitting(true);
    const { data, error } = await startDeployment(details);
    setSubmitting(false);
    if (error) return;
    setDeploymentDetails(data);
    showToast(
      "Deployment Started",
      "success",
      "This process may take upto 24 hours"
    );
    setStep(2);
  };

  useEffect(() => {
    const getDetails = async () => {
      setLoading(true);
      const { data, error } = await getAccountDetails();
      if (error) return setLoading(false);
      if (!data.status) setLoading(false);
      setDeploymentDetails(data);
    };
    getDetails();
  }, []);

  useEffect(() => {
    if (deploymentDetails.status) {
      const userQuery = doc(db, "baioniq-saas-users", deploymentDetails.uid);
      const unsubscribe = onSnapshot(userQuery, (querySnapshot) => {
        const { deploymentStatus, deploymentStages } = querySnapshot.data();
        setDeploymentDetails((prev) => ({
          ...prev,
          status: deploymentStatus,
          stages: deploymentStages,
        }));
        if (deploymentStatus === "COMPLETED") setStep(3);
        else setStep(2);
        setLoading(false);
      });
      return unsubscribe;
    }
  }, [deploymentDetails.status, deploymentDetails.uid]);

  if (loading)
    return (
      <div
        className="dashboard-wrapper"
        style={{ flexDirection: "row", justifyContent: "center" }}
      >
        <i className="pi pi-spin pi-spinner" style={{ fontSize: 64 }}></i>
        <span className="title">Loading</span>
      </div>
    );

  return (
    <div className="dashboard-wrapper">
      {step === 1 && (
        <>
          <div className="image_wrapper">
            <img src={baioniq_logo} alt="Baioniq" width={210} />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="input_row">
              <div className="input_container">
                <label>First Name</label>
                <input
                  required
                  value={details.firstName}
                  onChange={handleChange}
                  name="firstName"
                  placeholder="Your first name here"
                />
                <span></span>
              </div>
              <div className="input_container">
                <label>Last Name</label>
                <input
                  required
                  value={details.lastName}
                  onChange={handleChange}
                  name="lastName"
                  placeholder="Your last name here"
                />
                <span></span>
              </div>
            </div>
            <div className="input_row">
              <div className="input_container full">
                <label>Organization Name</label>
                <input
                  required
                  value={details.orgName}
                  onChange={handleChange}
                  name="orgName"
                  placeholder="Eg: Quantiphi"
                />
                <span></span>
              </div>
            </div>
            <div className="input_row">
              <div className="input_container full">
                <label>Business Email</label>
                <input
                  required
                  value={details.businessEmail}
                  onChange={handleChange}
                  name="businessEmail"
                  placeholder="Eg: johndoe@companyname.com"
                />
                <span>Please provide your business email</span>
              </div>
            </div>
            <div className="input_row">
              <div className="input_container full">
                <label>Admin Email</label>
                <input
                  required
                  value={details.adminEmail}
                  onChange={handleChange}
                  name="adminEmail"
                  placeholder="Eg: admin@companyname.com"
                />
                <span>
                  Please provide the email to be used for initial setup
                </span>
              </div>
            </div>
            <div className="input_row">
              <div className="input_container full">
                <label>Mobile Number</label>
                <input
                  required
                  value={details.contact}
                  onChange={handleChange}
                  name="contact"
                  placeholder="Eg: +91 555-22-45642"
                />
                <span>
                  Please provide your mobile number for any communication
                </span>
              </div>
            </div>
            <div className="input_row">
              <div className="input_container full">
                <label>Sub-Domain Name</label>
                <div className="domain-group">
                  <label>https://</label>
                  <input
                    required
                    maxLength={63}
                    value={details.domain}
                    onChange={(e) => {
                      e.persist();
                      e.target.value = e.target.value
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .replace(/[^A-Za-z0-9]/g, "-")
                        .toLowerCase()
                        .replace(/-{2,}/g, "-");
                      handleChange(e);
                    }}
                    name="domain"
                    placeholder="quantiphi"
                    onKeyDown={(e) => {
                      const regex = /^[a-zA-Z0-9-]/;
                      if (!regex.test(e.key)) e.preventDefault();
                    }}
                  />
                  <label>.baioniq.com</label>
                </div>
                <span>Please choose a sub-domain name of your choice</span>
              </div>
            </div>

            <div className="input_row">
              <div className="input_container full">
                <span className="footer-text">
                  By clicking submit below, you consent to allow Quantiphi to
                  store and process the personal information submitted above to
                  provide you the content requested.
                </span>
              </div>
            </div>
            <div className="input_row">
              <div className="input_container full">
                <button disabled={submitting} type="submit">
                  Proceed
                </button>
              </div>
            </div>
          </form>
        </>
      )}
      {step === 2 && (
        <div className="progress-wrapper">
          <img
            src={baioniq_logo}
            alt="Baioniq"
            width={210}
            style={{ margin: "auto" }}
          />
          <img
            src={deployment_img}
            alt="Deployment Status"
            width={"50%"}
            style={{ margin: "auto" }}
          />
          <span className="title">
            We are configuring your Baioniq instance. Please stay tuned.
          </span>
          <span className="description">
            This process may take upto 24 hours
          </span>
          <div className="progress-container">
            <ProgressBar
              value={completed}
              pt={{
                value: {
                  style: {
                    background:
                      "linear-gradient(270deg, #23b082 0%, #5284d9 100%)",
                  },
                },
                label: { style: { display: "none" } },
              }}
            ></ProgressBar>
            {/* <div className="progress-bar-container">
              <div
                className="progress-bar-indicator"
                style={{ width: `${completed}%` }}
              ></div>
            </div> */}
            <div className="label-wrapper">
              <span>{stageName}...</span>
              <span>{completed}%</span>
            </div>
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="progress-wrapper">
          <img
            src={baioniq_logo}
            alt="Baioniq"
            width={210}
            style={{ margin: "auto" }}
          />
          <span
            className="title"
            style={{
              marginBottom: 30,
              fontWeight: "400",
              letterSpacing: -0.5,
            }}
          >
            Configuration Completed
          </span>

          <span className="title" style={{ fontSize: 18 }}>
            Guidelines on how to login
          </span>
          <div className="steps-container">
            <div className="step-container">
              <span className="title">Step 1</span>
              <span className="box">
                Open your inbox ({localStorage.getItem("email")}) and find an
                email with the subject 'Your Baioniq Instance is Ready'
              </span>
            </div>
            <div className="step-container">
              <span className="title">Step 2</span>
              <span className="box">
                Click on the "Goto Baioniq" button within the email
              </span>
            </div>
            <div className="step-container">
              <span className="title">Step 3</span>
              <span className="box">
                Login via Google SSO using the same email mentioned in Step 1
              </span>
            </div>
          </div>

          <span className="title" style={{ fontSize: 18 }}>
            Thank you for choosing Baioniq.
          </span>
        </div>
      )}
      <span className="footer">
        For further queries or help, contact us:
        <br />
        <a href="mailto:baioniq@quantiphi.com">baioniqteam@quantiphi.com</a>
      </span>
    </div>
  );
}
